import React from 'react'
import {Link} from "react-router-dom";

function Navbar() {
  return (
    <div>
        <div className="fixed-top">
        <div className="p-2 mb-0 bg-secondary text-dark">
    <div className="d-flex justify-content-center">
    
      <a href="tel:  9732563323" className="btn btn-light btn-sm">Book Appointment</a> 
      

      </div>
    </div>

      <nav className="navbar navbar-expand-lg navbar-light bg-light">
  <div className="container-fluid"  >
    <Link to="/" className="navbar-brand  me-5" href="#"><b style={{color: "Darkblue"}}>ABC HOMOEO CARE</b></Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link to="/" className="nav-link active" aria-current="page" ><b>HOME</b></Link>
        </li>
        <li className="nav-item">
          <Link to="/Finddoctor" className="nav-link active" aria-current="page" ><b>FIND DOCTOR</b></Link>
        </li>
        <li className="nav-item">
          <Link to="/About" className="nav-link active" aria-current="page" ><b>ABOUT US</b></Link>
        </li>
        <li className="nav-item">
          <Link to="/Success" className="nav-link active" aria-current="page" ><b>OUR SUCCESS</b></Link>
        </li>
        <li className="nav-item">
          <Link to="/Contact" className="nav-link active" aria-current="page" ><b>CONTACT</b></Link>
        </li>
      
      
      
      </ul>
     
    </div>
  </div>
</nav>
</div>
    </div>
  )
}

export default Navbar

import React from "react";
import Navbar from "../inc/Navbar";
import About from "../pages/About";

import {Link} from "react-router-dom";
function Aboutpage (){
    return(
        <>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <div className="p-2 mb-0 bg-light text-light">
    
    <div className="album py-4">
            
            <div className="container">
    
                <div className="row ">
           
                <div className="col-md-7" >
                    <br/>
                    
    
                    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
        <li className="breadcrumb-item active" aria-current="page">About Us</li>
      </ol>
    </nav>
                                   
    </div>
    </div>
    </div>
    </div>
    </div>
        <About/>
        </>
    );
}

export default Aboutpage;




import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
  apiKey: "AIzaSyB8SWIJDvveoLrVXkKcZibCYNf_vIKHxt4",
  authDomain: "abc-care-ac10c.firebaseapp.com",
  projectId: "abc-care-ac10c",
  storageBucket: "abc-care-ac10c.appspot.com",
  messagingSenderId: "17929853044",
  appId: "1:17929853044:web:3c0bff5f5090129afed921",
  measurementId: "G-XE9RWL9EL9"
 
};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;




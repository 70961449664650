
import './App.css';
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';


import Footer from "./components/inc/Footer";
import Home from "./components/pages/Home";
import Finddoctor from "./components/pages/Finddoctor";
import Contact from "./components/pages/Contact";
import Aboutpage from "./components/pages/Aboutpage";
import Success from "./components/pages/Success";
function App() {
  return (
    
    <Router>
      
  
    
        <div>
      
         
          
          <Routes>
     
         
          <Route axact path="/" element={<Home/>}/>
          <Route axact path="/Finddoctor" element={<Finddoctor/>}/>
          <Route axact path="/Contact" element={<Contact/>}/>
          <Route axact path="/About" element={<Aboutpage/>}/>
          <Route axact path="/Success" element={<Success/>}/>
          </Routes>
    <Footer/>
  
    </div>
   
    </Router>
   
  );
}

export default App;

import React from 'react'
import Avhikbiswas from "../images/abcdirector.jpg";
function About() {
  return (
    <div>
       <div className="album py-4">
        
        <div className="container">

            <div className="row">
       
            <div className="col-md-6 mt-8" >

                       
            <img src={Avhikbiswas} className="card-img-top"   alt="Avhikbiswas" />
                                
                                <div className="d-flex justify-content-between align-items-center">
                               
                        
                            </div>
                               
                            </div>
                            <div className="col-md-5 mt-6" >
                        
                                <h2 className="card-text"> <b>Abhik Biswas</b></h2>
                                <h5 className="card-text" style={{color:"Gray"}}>DIRECTOR</h5>
                                <p className="card-text">THE ABC HOMOEO CARE IS AN INSTITUTION OF HOMOEOPATHY
                                 WHICH COMPLETELY BELIEVES AND FOLLOWS THE CARDINAL PRINCIPLES OF CLASSICAL
                                  HOMOEOPATHY, i.e., "SIMILIA SIMILIBUS CURENTUR". HOMOEOPATHY FOLLOWS A HOLISTIC
                                   APPROACH TO TREATMENT, IN WHICH THIS MEDICINE SYSTEM TREATS THE WHOLE PATIENT 
                                   NOT THE DISEASE ONLY. FOR THIS REASON, THE PATIENT IS NOT ONLY RECOVERING FROM
                                    HIS SUFFERINGS. BUT ALSO EXPERIENCE A" SENSE OF WELL-BEING".EVEN, WHERE THE
                                     DISEASE IS INCURABLE OR PARTS OF THE ORGAN ARE PERMANENTLY DAMAGED, THE PATIENT
                                      CAN EXPERIENCE THE SAME ALSO. THIS IS A MORE THAN 200 YEARS OLD, SUCCESSFUL 
                                      SYSTEM OF MEDICINE DISCOVERED BY DR. SAMUEL HANNEMAN(1755-1843) IN 1795 IN
                                      GERMANY, PRACTICED AND PROMOTED BY HIMSELF AND HIS SUCCESSFUL STUDENTS AND 
                                      LEGENDS OF HOMOEOPATHY, AND SPREAD ALL OVER THE WORLD. IN BENGAL AS WELL AS 
                                      IN OTHER PROVINCES IN UNDIVIDED INDIA, HOMEOPATHY IS NOT ONLY PRACTICED BY
                                       ENORMOUS, LEGENDARY HOMEOPATHIC PHYSICIANS BUT ALSO BY A LARGE NUMBER OF 
                                       ALLOPATHIC PHYSICIANS AND RESPECTED FREEDOM FIGHTERS, SOCIAL REFORMERS, 
                                       AND OTHER FAMOUS PERSONALITIES; AMONG THEM, PANDIT ISHWAR CHANDRA VIDYASAGAR,
                                        SWAMI VIVEKANANDA, DR.MAHENDRALAL SARKAR ETC ARE FEW NAME. DR.MAHENDRALAL
                                         SARKAR WHO WAS A RENOWNED PHYSICIAN OF OLD-SCHOOL MEDICINE PRACTICED
                                          HOMEOPATHY IN HIS LATER PHASE OF LIFE AFTER OBSERVING THE POTENTIALITY
                                           OF THIS HEALING ART & HE CONVERTED HIMSELF TO A HOMEOPATHIC PHYSICIAN &
                                            PRACTICED TILL HIS LAST BREATH. THE "ABC HOMEO CARE" FOLLOWS ITS PATH
                                             OF SERVING THE SOCIETY AND MANKIND AND WANTS TO GIVE THE EXPERIENCE OF
                                              "THE TRUE SENSE OF WELL-BEING" WITH THIS REPEATEDLY VERIFIED SYSTEM
                                               OF MEDICINE -"CLASSICAL HOMEOPATHY" WHICH PROVES ITSELF AGAIN AND 
                                               AGAIN AS A SAVIOUR OF MANKIND. THANK YOU. ---MR. ABHIK BISWAS 
                                               (DIRECTOR & OWNER OF ABC HOMEO CARE).</p>
                                
                                <div className="d-flex justify-content-between align-items-center">
                         
                            </div>
                               
                            </div>
                            </div>
                            </div>
                            </div>

<hr/>
    </div>
  )
}

export default About

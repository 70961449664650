import React from 'react'
import Navbar from '../inc/Navbar'
import Slider from '../inc/Slider'
import Abc from "../images/abc.jpg";
import Abchomoeo from "../images/ABCHOMOEO.jpg";
import Abcclinic from "../images/clinic2.jpg";
import Abchomoeocare from "../images/abchomoeokrishnanagar.jpg";

import {Link} from "react-router-dom";
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import bgImage from "../images/abchomoeo.mp4";
import ScrollToTop from "react-scroll-to-top";
import Mapgoogle from '../pages/Mapgoogle';
import About from "../pages/About";
import "./Home.css";
function Home() {
  return (
    <div>
      <Navbar/>
      <br/>
      <br/>
      <br/>
      <ScrollToTop smooth top="100"/>
      <div className="album py-0">
        
        <div className="container">

            <div className="row">
              <h5>abc homoeo care</h5>
              </div>
              </div>
              </div>
             <Slider/>
      <div style={{color: "Gray", padding:"14px;"}} className="fixed-bottom "> <a href="tel: +91  9732563323" className="btn btn-primary" > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-phone" viewBox="0 0 16 16">
  <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"/>
  <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
</svg>  Call Us <div className="spinner-grow text-light spinner-grow-sm"  role="status">
              <span className="visually-hidden"> Loading...</span>
            </div> </a></div>
      <div className="abchomoeobackground">
        <br/>
      <div className="album py-0">
        
        <div className="container">
            <div className="row">
              <h2><b>Our Clinic</b></h2>
              </div>
              </div>
            </div>
            <div className="album py-0">
        <div className="container">

            <div className="row">
              
<Video autoPlay loop  onCanPlayThrough={()=>{
    console.log('video play')
}}>
<source src={bgImage}  type="video/webm"/>
</Video>
</div></div>
</div>
        
            </div>
          
       
                <div style={{  
  backgroundImage: "url(" + "https://i.postimg.cc/bYF31bLt/IMG-20220303-190150.jpg" + ")",
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat'
}}>
      <br/>
  <br/>  
  <br/>
  <br/>  


  <br/>
  <br/>  

  <br/>
  <br/>  
  <br/>
  <br/>  
  <br/>
  <br/> 
  <br/>
  <br/> 
     <div className="album py-1">
        
        <div className="container">

            <div className="row">
              
            <h1 style={{ fontSize: "2rem", color: "white", fontSize: "3rem"}} className="card-text mb-2"> <b>ABC HOMOEO CARE</b></h1>
           
                <h1 style={{color:"white", fontSize: "3rem"}} className="card-text mb-2"> <b>INDIA'S NO1 TRUSTED VERIFY DOCTOR</b></h1>
                
               
            </div>
            <Link to="/finddoctor">
            <button className="btn btn-light">Choose Doctor CLICK NOW</button>
            </Link>
            </div>
            
            </div>
<br/>
 



<br/> 
</div>
            <div className="album py-4">
        
        <div className="container">

            <div className="row">
              <h2><b>About Us</b></h2>
              </div>
              </div>
            </div>
      <div className="album py-4">
        
        <div className="container">

            <div className="row">
       
            <div className="col-md-6 mt-8">

                       
            <img src={Abc} className="card-img-top"   alt="abchomoeocare" />
                                
                                <div className="d-flex justify-content-between align-items-center">
                               
                        
                            </div>
                               
                            </div>
                            <div className="col-md-5 mt-6" >
                            <h1 className="card-text mb-5"> <b>ABC HOMOEO CARE</b></h1>
                                <h5 style={{color:"Gray"}} className="card-text mb-3"> <b>An Optomistic Voyage</b></h5>
                                <p className="card-text">
We will build your website in a way that makes quality healthcare affordable and accessible to the people of India. People
All countries can easily consult the right doctor about their health</p>
                             
                                
                                <div className="d-flex justify-content-between align-items-center">
                         
                            </div>
                               
                            </div>
                            </div>
                            </div>
                            </div>
                          

<br/>
<hr/>
<div className="album py-0">
        
        <div className="container">

            <div className="row">
<h3 style={{color: "Gray"}}> Our Clean <b>ABC HOMOEO CARE</b> CLINIC</h3>
</div>
</div></div>
            


<div className="album py-4">
        
        <div className="container">

            <div className="row">
       
            <div className="col-md-4 mt-8" >
                        <div className="card mb-4 border-White box shadow">
                        <img src={Abchomoeo} className="card-img-top"   alt="Abchomoeo" />
                            <div className="card-body">
                                
                                <h6 className="card-text"> <b>Abc Homoeo Care</b></h6>
                                
                                
                                  <div className="d-flex justify-content-between align-items-center">
                                        
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-4 mt-6" >
                        <div className="card mb-4 border-white box shadow">
                        <img src={Abcclinic} className="card-img-top"   alt="Abcclinic" />
                            <div className="card-body">
                                <h6 className="card-text"><b>Abc Homoeo Care </b></h6>
                                


                  
<div className="d-flex justify-content-between align-items-center">
                                
                                        
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            {/* style={{textAlign: "center"}} */}
                            <div className="col-md-4" >
                        <div className="card mb-4 border-white box shadow">
                        <img src={Abchomoeocare} className="card-img-top"   alt="Abchomoeocare" />
                            <div className="card-body">
                                <h6  className="card-text"><b>Abc Homoeo Care </b></h6>
                            
                                
                               
                                      
                                <div className="d-flex justify-content-between align-items-center">
                                   
                               
                               
                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            </div>
                            </div>
                            </div>

             <About/>
             <Mapgoogle/>
    </div>
  )
}

export default Home

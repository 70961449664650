import React from 'react'
import Navbar from "../inc/Navbar";
import Piles from "../images/PPP.jpg";
import {Link} from "react-router-dom";
function Success() {
  return (
    <div>
      <Navbar/>
      <br/>
      <br/>
      <br/>
      <br/>
    <br/>
    
      <div className="album py-1">
            
            <div className="container">
    
                <div className="row ">
           
               
                    <br/>
                    
    
                    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
        <li className="breadcrumb-item"><Link to="/Finddoctor" >Find Doctor</Link></li>
        <li className="breadcrumb-item active" aria-current="page">Piles Treatment Success</li>
      </ol>
    </nav>
                                   
    </div>
    </div>
    </div>
   

      <marquee width="80%">
        <img src={Piles} className="img-fluid" alt="Piles"/>
        </marquee>

    </div>
  )
}

export default Success

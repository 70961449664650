import React, { useState, useEffect }  from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";

import {db} from '../../firebase'
import ScrollToTop from "react-scroll-to-top";

import Navbar from '../inc/Navbar';
function Finddoctor() {
    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(6);
    const [pagination, setPagination] =useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start,  end) =>{
        setPagination({start: start, end: end});
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [doctors, setDoctors] = useState([])
    const navigate = useNavigate()

    const doctorsCollectionRef = collection(db, "doctors");
    useEffect(() => {

        const getDoctors = async () => {
            const data = await getDocs(doctorsCollectionRef);
            setDoctors(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
        }

        getDoctors()
    }, [])
    return (
<>
<Navbar/>
<br/>
<br/>
<br/>
<br/>
<ScrollToTop smooth top="100"/>

<br/>
<br/>
<h1 style={{textAlign: "center", color: "darkCyan"}}> 
<b>#India's No1 Trusted Verify Doctor</b></h1>
<div className="album py-2">
        
        <div className="container">

            <div className="row">
            <div className="col-md-6">
                    
                    <div className="card-body">
                    <input type="text" class="form-control" placeholder="Search Doctors " name="location" 
                    onChange ={(e) => {
                      setsearchTerm(e.target.value);
                    }}
                    />
                            <div className="d-flex justify-content-between align-items-center">
                               
                                
                        

                        </div>
                      
                
                    </div>

                  
                </div>
            
                </div>
                </div>
                </div>

<div className="album py-4">
        
        <div className="container">

            <div className="row">
            {doctors.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } else if (
        val.drname.toLowerCase().includes(searchTerm.toLowerCase()) 
  
      
       
       
  
      ){
        return val;
      }
    }).slice(pagination.start, pagination.end).map((Doctor) => { return (
       
            <div className="col-md-4 mt-4" >
                        <div className="card mb-4 border-White box shadow">
                        <img  className="card-img-top" src={Doctor.img}   alt={Doctor.drname} />
                            <div className="card-body">
                                
                                <h6 className="card-text"> <b> Dr. { Doctor.drname} <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{ color: "DodgerBlue"}} fill="currentColor" class="bi bi-patch-check-fill" viewBox="0 0 16 16">
  <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z"/>
</svg></b></h6>
<p className="card-text mb-2">{Doctor.ex}</p>
                                <p className="card-text mb-2">{Doctor.degree}</p>
                                <p className="card-text mb-2"> {Doctor.exp} </p>
                                <p className="card-text mb-2">{Doctor.reg}</p>
                                <p className="card-text mb-2"> {Doctor.visit} </p>
                                <a href="tel: +91 9732563323"  className="btn btn- btn-primary">  নাম লেখানর জন্য  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z"/>
</svg>  +91 9732563323</a> 
                                <div className="d-flex justify-content-between align-items-center">                              
                                        {/* <button type="button" className="btn btn btn-dark">
                                        {website1.workf} 
                                        </button>  */}

                               
                            </div>
                            </div>
                            </div>
                          
                            </div>
                                )})} 
                            </div>
                            {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={doctors.length}
                /> */}
                            </div>
                            </div>
</>

);
}

export default Finddoctor;
import React from 'react'

function Mapgoogle() {
  return (
    <div>
            <div className="album py-0">
        
        <div className="container">

            <div className="row">
          

      <iframe className="iframe-fluid"   height="436"  frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=ABC%20Homoeo%20Care,%2016,%20RN%20Tagore%20Rd,%20Krishnanagar,%20West%20Bengal%20741101%20Krishnanagar+(ABC%20Homoeo%20Care,%2016,%20RN%20Tagore%20Rd,%20Krishnanagar,%20West%20Bengal%20741101)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>

                </div>
                </div>
                </div>
    </div>
  )
}

export default Mapgoogle
